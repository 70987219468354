import ColorLensIcon from "@mui/icons-material/ColorLens";
import SchoolIcon from "@mui/icons-material/School";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopHouse,
  faBook,
  faBuilding,
  faDesktop,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";

export const localisation = {
  ENGLISH: "en-my",
  BAHASA_MALAYSIA: "ms-my",
};

export const profileOverviewSections = [
  {
    id: 1,
    sectionName: "Work Experience",
    visible: true,
  },
  {
    id: 2,
    sectionName: "Education",
    visible: true,
  },
  {
    id: 3,
    sectionName: "Projects",
    visible: true,
  },
  {
    id: 4,
    sectionName: "Certificates and Training",
    visible: true,
  },
  {
    id: 5,
    sectionName: "Honours and Awards",
    visible: true,
  },
  {
    id: 6,
    sectionName: "Extracurricular",
    visible: true,
  },
  {
    id: 7,
    sectionName: "Language",
    visible: true,
  },
  {
    id: 8,
    sectionName: "Skills",
    visible: true,
  },
];

export const jobCategoryFilters = [
  {
    id: 1,
    title: "Fresh Graduate Jobs",
    icon: <SchoolIcon fontSize="inherit" />,
    url: `/jobs?experiences=${encodeURIComponent(JSON.stringify([2]))}`,
    element_id: "trending-categories-fresh-grad",
  },
  {
    id: 2,
    title: "Internships",
    icon: <FontAwesomeIcon icon={faBook} />,
    url: `/jobs?experiences=${encodeURIComponent(
      JSON.stringify([1])
    )}&job_types=${encodeURIComponent(JSON.stringify([4]))}`,
    element_id: "trending-categories-internship",
  },
  {
    id: 3,
    title: "IT Jobs",
    icon: <FontAwesomeIcon icon={faDesktop} />,
    url: `/jobs?tracks=${encodeURIComponent(JSON.stringify([14, 32, 33]))}`,
    element_id: "trending-categories-it",
  },
  {
    id: 4,
    title: "Jobs in Klang Valley",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    url: `/jobs?states=${encodeURIComponent(
      JSON.stringify(["Kuala Lumpur", "Selangor", "Putrajaya"])
    )}`,
    element_id: "trending-categories-klang-valley",
  },
  {
    id: 5,
    title: "Creative Jobs",
    icon: <ColorLensIcon />,
    url: `/jobs?tracks=${encodeURIComponent(JSON.stringify([17, 30, 31]))}`,
    element_id: "trending-categories-creative",
  },
  {
    id: 6,
    title: "Part-Time Jobs",
    icon: <FontAwesomeIcon icon={faBusinessTime} />,
    url: `/jobs?job_types=${encodeURIComponent(JSON.stringify([3]))}`,
    element_id: "trending-categories-part-time",
  },
  {
    id: 7,
    title: "Remote Working Jobs",
    icon: <FontAwesomeIcon icon={faLaptopHouse} />,
    url: `/jobs?remote_search=${encodeURIComponent("remote")}`,
    element_id: "trending-categories-remote",
  },
];

export const proficiency = ["Conversational", "Professional", "Native"];

export const languages = [
  "Afrikaans",
  "Arabic",
  "Bangla",
  "Bulgarian",
  "Burmese",
  "Cantonese",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hokkien",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Khmer",
  "Korean",
  "Lao",
  "Malay",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Spanish",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
];

export const nationalities = [
  "Malaysian",
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Ni-Vanuatu",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
  "Not Listed",
];

export const institutions = [
  "Al-Madinah International University (MEDIU)",
  "Asia e University (AeU)",
  "Asia Metropolitan University (AMU)",
  "Asia Pacific University of Technology & Innovation (APU)",
  "Asia School of Business (ASB)",
  "Asian Institute of Medical Science and Technology (AIMST University)",
  "Binary University of Management & Entrepreneurship (BUME)",
  "Curtin University Malaysia",
  "GlobalNxt University",
  "HELP University",
  "Heriot-Watt University Malaysia",
  "Infrastructure University KL (IUKL)",
  "International Centre for Education in Islamic Finance (INCEIF)",
  "International Medical University (IMU)",
  "International University of Malaya-Wales (IUMW)",
  "INTI International University",
  "KDU University College (KDU)",
  "Kampus Cawangan Malaysian Institute of Aviation Technology (UNIKL-MIAT)",
  "Limkokwing University of Creative Technology (LUCT)",
  "MAHSA University",
  "Malaysia University of Science and Technology (MUST)",
  "Malaysian Institute For Supply Chain Innovation (MISI)",
  "Management and Science University (MSU)",
  "Manipal International University (MIU)",
  "Meritus University",
  "Monash University Malaysia",
  "Multimedia University (MMU)",
  "Nilai University",
  "Newcastle University Medicine Malaysia",
  "Open University Malaysia (OUM)",
  "Perdana University",
  "Politeknik Balik Pulau",
  "Politeknik Banting",
  "Politeknik Hulu Terengganu",
  "Politeknik Ibrahim Sultan",
  "Politeknik Jeli",
  "Politeknik Kota Bharu",
  "Politeknik Kota Kinabalu",
  "Politeknik Kuala Terengganu",
  "Politeknik Kuching Sarawak",
  "Politeknik Melaka",
  "Politeknik Merlimau",
  "Politeknik Mersing",
  "Politeknik Muadzam Shah",
  "Politeknik Mukah Sarawak",
  "Politeknik Nilai",
  "Politeknik Port Dickson",
  "Politeknik Sandakan",
  "Politeknik Seberang Perai",
  "Politeknik Sultan Abdul Halim Mu'adzam Shah",
  "Politeknik Sultan Azlan Shah",
  "Politeknik Sultan Haji Ahmad Shah",
  "Politeknik Sultan Idris Shah",
  "Politeknik Sultan Mizan Zainal Abidin",
  "Politeknik Sultan Salahuddin Abdul Aziz Shah",
  "Politeknik Tuanku Sultanah Bahiyah",
  "Politeknik Tuanku Syed Sirajuddin",
  "Politeknik Tun Syed Nasir Syed Ismail",
  "Politeknik Ungku Omar",
  "Quest International University Perak (QIUP)",
  "Raffles University (Raffles)",
  "SEGI University (SEGI)",
  "Sunway University",
  "Swinburne University of Technology Sarawak",
  "Taylor's University",
  "UCSI University",
  "UNITAR International University (UNITAR)",
  "Universiti Antarabangsa AlBukhary (AIU)",
  "Universiti Islam Antarabangsa Malaysia (UIAM)",
  "Universiti Islam Malaysia (UIM)",
  "Universiti Kebangsaan Malaysia (UKM)",
  "Universiti Kuala Lumpur (UNIKL)",
  "Universiti Kuala Lumpur Kampus Cawangan British Malaysia Institute (UNIKL-BMI)",
  "Universiti Kuala Lumpur Kampus Cawangan Institute of Medical Science (UNIKL-MESTEC)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian France Institute (UNIKL- MFI)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Institute of Chemical & Bio-Engineering Technology (UNIKL-MICET)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Institute of Industrial Technology (UNIKL-MITEC)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Institute of Marine Engineering Technology (UNIKL-MIMET)",
  "Universiti Kuala Lumpur Kampus Cawangan Malaysian Spanish Institute (UNIKL-MSI)",
  "Universiti Kuala Lumpur Kampus Cawangan Royal College of Medicine Perak (UniKL RCMP)",
  "Universiti Malaya (UM)",
  "Universiti Malaysia Kelantan (UMK)",
  "Universiti Malaysia Pahang (UMP)",
  "Universiti Malaysia Perlis (UniMAP)",
  "Universiti Malaysia Sabah (UMS)",
  "Universiti Malaysia Sarawak (UNIMAS)",
  "Universiti Malaysia Terengganu (UMT)",
  "Universiti Pendidikan Sultan Idris (UPSI)",
  "Universiti Pertahanan Nasional Malaysia (UPNM)",
  "Universiti Putra Malaysia (UPM)",
  "Universiti Sains Islam Malaysia (USIM)",
  "Universiti Sains Malaysia (USM)",
  "Universiti Selangor (UNISEL)",
  "Universiti Sultan Azlan Shah (USAS)",
  "Universiti Sultan Zainal Abidin (UniSZA)",
  "Universiti Teknikal Malaysia Melaka (UTeM)",
  "Universiti Teknologi Malaysia (UTM)",
  "Universiti Teknologi MARA (UiTM)",
  "Universiti Teknologi Petronas (UTP)",
  "Universiti Tenaga Nasional (UNITEN)",
  "Universiti Terbuka Wawasan (WOU)",
  "Universiti Tun Abdul Razak (UNIRAZAK)",
  "Universiti Tun Hussein Onn Malaysia (UTHM)",
  "Universiti Tunku Abdul Rahman (UTAR)",
  "Universiti Utara Malaysia (UUM)",
  "University Malaysia of Computer Science and Engineering (UniMY)",
  "University of Nottingham Malaysia",
  "University of Reading Malaysia",
  "University of Southampton Malaysia",
  "Xiamen University Malaysia",
];

export const educationLevels = [
  "Secondary School",
  "Pre-University (A-Levels, Foundation, Diploma or equivalent)",
  "Undergraduate (Bachelor's Degree or equivalent)",
  "Postgraduate (Master's Degree or equivalent or higher)",
  "Other",
];

export const fields = [
  "Business",
  "Creative Arts, Design, Architecture",
  "Engineering",
  "Health Science",
  "Hospitality, Tourism and Culinary",
  "IT",
  "Law",
  "Social Science",
];

export const genders = ["Male", "Female"];

export const interestedJobTitles = [
  // Accounting & Finance
  "Accountant",
  "Accounting/Finance Clerk",
  "Auditor",
  "Financial Analyst",
  "Actuary",
  "Controller",
  "Risk Consultant",
  "Treasurer",
  "Economist",
  // Creative
  "Graphic Designer",
  "Videographer",
  "Photographer",
  "Interior Designer",
  "Illustrator",
  "Instructional Designer",
  "Web Designer",
  "Industrial Designer",
  "Game Designer",
  "UX/UI Designer",
  "Editor",
  "Content Strategist",
  "Sound Engineer",
  "Animator",
  "Musician",
  "Writer",
  // Education
  "Academic Adviser/Coordinator",
  "Professor",
  "Coach/Teacher",
  "Program Coordinator",
  "Teaching Assistant",
  // Engineering
  "Chemical Engineer",
  "Electrical Engineer",
  "Electronics Engineer",
  "Environmental Engineer",
  "Civil Engineer",
  "Industrial Engineer",
  "Mechanical Engineer",
  "Oil/Gas Engineer",
  // Human Resources
  "Recruiter",
  "Human Resources Analyst",
  "Recruiting Coordinator",
  "Human Resources Associate",
  // Information Technology
  "Cloud Computing Engineer",
  "Computer Network Specialist/Engineer",
  "Computer Support Specialist",
  "System Analyst",
  "Information Security Specialist",
  "Software/Application/Web Developer",
  "Data Analyst",
  "Data Engineer",
  "Data Scientist",
  "DevOps Engineer",
  "Quality Assurance Analyst",
  // Legal
  "Legal Assistant",
  "Patent Agent",
  "Contract Administrator",
  "Lawyer",
  "Legal Analyst/Consultant",
  "Legal Writer",
  // Healthcare
  "Nurse",
  "Doctor",
  "Physical Therapist",
  "Pharmacist",
  "Medical Administrator",
  "Psychologist",
  // Marketing
  "Marketing Associate",
  "Marketing Coordinator",
  "Digital Marketing Associate",
  "Brand Ambassador",
  "Marketing Analyst",
  "Marketing Consultant",
  "Copywriter",
  // Operations & Admin
  "Administrator",
  "Secretary",
  "Account/Billing Coordinator",
  "Data Entry",
  "Support Specialist",
  "Receptionist",
  "Customer Service",
  // Hospitality
  "Event Coordinator",
  "Event Planner",
  "Chef/Pastry Chef",
  "Attendant",
  "Barista",
  "Waiter/Waitress",
  // Sales
  "Sales Associate/Consultant",
  "Sales Development Representative",
  "Inside Sales Representative",
  "Outside Sales Representative",
  "Business Development Representative",
  // Supply Chain and Logistics
  "Demand Planning Specialist",
  "Warehouse Assistant",
  "Logistics Management",
  "Purchasing Assistant",
  "Supply Specialist/Coordinator",
  "Driver",
  "Logistics Coordinator/Associate",
  "Supply Chain Associate",
];

export const CHAT_CHANNEL_HANDLER_ID = "CHAT_HANDLER";
export const CHAT_LIST_CHANNEL_HANDLER_ID = "CHAT_LIST_HANDLER";

// for SEO static page
// slug is capitalized to match query params
export const experienceLevels = [
  {
    experienceLevel: "Intern",
    slug: "intern",
  },
  {
    experienceLevel: "Fresh Graduate",
    slug: "fresh-graduate",
  },
  {
    experienceLevel: "Junior Executive", // 1 to 3 years of experience
    slug: "junior-executive",
  },
  {
    experienceLevel: "Senior Executive", // 4 to 7 years of experience
    slug: "senior-executive",
  },
  {
    experienceLevel: "Manager", // 8 to 10 years of experience
    slug: "manager",
  },
  {
    experienceLevel: "Senior Manager", // over 10 years of experience
    slug: "senior-manager",
  },
];

// for SEO static page
// slug is capitalized to match query params
export const stateRegions = [
  {
    stateRegion: "Kuala Lumpur",
    slug: "kuala-lumpur",
  },
  {
    stateRegion: "Selangor",
    slug: "selangor",
  },
  {
    stateRegion: "Johor",
    slug: "johor",
  },
  {
    stateRegion: "Kedah",
    slug: "kedah",
  },
  {
    stateRegion: "Kelantan",
    slug: "kelantan",
  },
  {
    stateRegion: "Malacca",
    slug: "malacca",
  },
  {
    stateRegion: "Negeri Sembilan",
    slug: "negeri-sembilan",
  },
  {
    stateRegion: "Pahang",
    slug: "pahang",
  },
  {
    stateRegion: "Penang",
    slug: "penang",
  },
  {
    stateRegion: "Perak",
    slug: "perak",
  },
  {
    stateRegion: "Perlis",
    slug: "perlis",
  },
  {
    stateRegion: "Sabah",
    slug: "sabah",
  },
  {
    stateRegion: "Sarawak",
    slug: "sarawak",
  },
  {
    stateRegion: "Terengganu",
    slug: "terengganu",
  },
  {
    stateRegion: "Overseas",
    slug: "overseas",
  },
];

// for SEO static page
// slug is capitalized to match query params
export const jobTypes = [
  {
    jobType: "Freelance",
    slug: "freelance",
  },
  {
    jobType: "Internship",
    slug: "internship",
  },
  {
    jobType: "Part-Time",
    slug: "part-time",
  },
  {
    jobType: "Full-Time",
    slug: "full-time",
  },
];

// for SEO static page
export const specialisations = [
  {
    track: "Creative",
    specialisation: "Creative - Videography & Photography",
    slug: "creative-videography-photography-jobs",
  },
  {
    track: "Creative",
    specialisation: "Creative - Visual Design",
    slug: "creative-visual-design-jobs",
  },
  {
    track: "Creative",
    specialisation: "Creative - Writing",
    slug: "creative-writing-jobs",
  },
  {
    track: "Marketing",
    specialisation: "Marketing - Digital",
    slug: "digital-marketing-jobs",
  },
  {
    track: "Marketing",
    specialisation: "Marketing - General",
    slug: "marketing-jobs",
  },
  {
    track: "IT",
    specialisation: "IT - Data Science & Analytics",
    slug: "it-data-science-analytics-jobs",
  },
  {
    track: "IT",
    specialisation: "IT - General",
    slug: "it-jobs",
  },
  {
    track: "IT",
    specialisation: "IT - Software Development",
    slug: "it-software-development-jobs",
  },
  {
    track: "Sales",
    specialisation: "Sales",
    slug: "sales-jobs",
  },
  {
    track: "Customer Service",
    specialisation: "Customer Service",
    slug: "customer-service-jobs",
  },
  {
    track: "Accounting & Finance",
    specialisation: "Accounting & Finance",
    slug: "accounting-finance-jobs",
  },
  {
    track: "Ops & Admin",
    specialisation: "Operations & Admin",
    slug: "operations-admin-jobs",
  },
  {
    track: "Engineering",
    specialisation: "Engineering",
    slug: "engineering-jobs",
  },
  {
    track: "HR",
    specialisation: "Human Resources",
    slug: "human-resources-jobs",
  },
  {
    track: "Management Trainee",
    specialisation: "Management Trainee",
    slug: "management-trainee-jobs",
  },
  {
    track: "Education",
    specialisation: "Education",
    slug: "education-jobs",
  },
  {
    track: "Supply Chain & Logistics",
    specialisation: "Supply Chain & Logistics",
    slug: "supply-chain-logistics-jobs",
  },
  {
    track: "Retail",
    specialisation: "Retail",
    slug: "retail-jobs",
  },
  {
    track: "Project Management",
    specialisation: "Project Management",
    slug: "project-management-jobs",
  },
  {
    track: "Legal",
    specialisation: "Legal",
    slug: "legal-jobs",
  },
  {
    track: "Other",
    specialisation: "Other",
    slug: "other-jobs",
  },
];

export const months = [
  { label: "Jan", id: 1 },
  { label: "Feb", id: 2 },
  { label: "Mar", id: 3 },
  { label: "Apr", id: 4 },
  { label: "May", id: 5 },
  { label: "Jun", id: 6 },
  { label: "Jul", id: 7 },
  { label: "Aug", id: 8 },
  { label: "Sep", id: 9 },
  { label: "Oct", id: 10 },
  { label: "Nov", id: 11 },
  { label: "Dec", id: 12 },
];
