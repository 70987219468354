export default {
  landingPageTitle: "Wait ah...",
  landingPageSubtitle: "Better job opportunities are on the way",
  iAm: "I am",
  registerInterest: "Register Interest",
  aJobSeeker: "a Jobseeker",
  anEmployer: "an Employer",
  submissionSuccess: "Your submission was successful!",
  invalidEmail: "Email is invalid!",
  somethingWentWrong: "Oops, something went wrong! Please try again later.",
  somethingWentWrongSubtitle: "Maybe you can find what you need below?",
  selectPlaceholder: "Select",
  promptSelectIdentity: "Please select your identity",
  until: "until",
  isLaunched: "is launched!",
  email: "Email",
  success: "Success",
  error: "Error",
  remindMe: "Remind Me",

  // nav bar
  login: "Login",
  signup: "Sign Up",
  profile: "Profile",
  logout: "Logout",
  jobs: "Jobs",
  companies: "Companies",
  forEmployer: "For Employer",
  home: "Home",
  startHiring: "Start hiring today",

  // homepage
  homepageTagline: "UPGRADE GAJI, UPGRADE HIDUP.",
  homepageSearchText: "What are you looking for?",
  homepageSearchButtonText: "Discover Now",
  homepageTrendingTitleText: "Trending Jobs",
  homepageTrendingJobButtonText: "Discover More",

  // job card
  applyButtonText: "Apply",
  appliedButtonText: "Applied",
  applyJob: "Successfully applied job!",

  // footer
  contactUs: "Contact Us",
  faq: "FAQ",
  copyright: "© Copyright",
  communityGuidelines: "Community Guidelines",
  privacyPolicy: "Privacy Policy",
  termsAndCondition: "Terms & Conditions",
  companies: "Companies",
  more: "More",
  close: "Close",
  footerIntro:
    "NaikGaji, founded in 2023, is committed to empowering the backbone of industries in Malaysia by providing a non-executive job platform that matches their aspirations.",

  // jobs page
  specialisationFilter: "Job Category",
  statesFilter: "States",
  jobTypeFilter: "Job Types",
  salaryFilter: "Salary",
  search: "Search",
  experienceLevelFilter: "Experience Level",
  clearFilter: "Clear All",
  applyFilter: "Apply Filter",
  showFilters: "Show Filters",
  hideFilters: "Hide Filters",

  // companies page
  industry: "Industry",

  // company card
  jobs: "Jobs",

  // contact us page
  contactUsTitleText: "We'd love to hear from you",
  contactUsFormBoxPlaceholder:
    "Let us know if you have any questions, feedback or suggestions! We will get back to you within 1 working day.",
  submit: "Submit",

  // single jobs page
  visitCompanyProfile: "Visit Company Profile",

  // employer page
  hireBestTalentCopy: "Hire talent like the best companies in Malaysia",

  // onboarding
  optional: "Optional",
  basicInfo: "Basic Information",
  fullName: "Full Name",
  gender: "Gender",
  birthDate: "Birth date",
  nationality: "Nationality",
  mobileNumber: "Mobile Number *",
  currentCompany: "Current Company",
  back: "Back",
  next: "Next",
  educationBg: "Education background",
  highestEduLvl: "Highest Education Level",
  gradYear: "Graduation Year",
  eduInstitute: "Education Institution",
  fieldOfStudy: "Field of Study",
  major: "Major",
  uploadResume: "Upload Resume",
  dropOrBrowse: "Drop your resume here or",
  browse: "Browse",
  supportFileType: "Support file type: .pdf, .doc, .docx",
  skip: "Skip",
  done: "Done",
  save: "Save",
  shortSummary: "Short Summary",

  // snackbar
  saveProfileSuccess: "Save profile success!",
  genderIsRequired: "Gender is required!",
  educationSaved: "Education saved!",
  educationCannotSave: "Education could not be saved, please try again later.",
  fileFormatWrong: "File should be in .doc/.docx/.pdf format",
  resumeUploadSuccess: "Upload resume success!",
  nameIsRequired: "Name is required!",
  phoneNumberRequired: "Phone number is required!",
  emailIsRequired: "Email is required!",
  passwordRequired: "Password is required!",
  confirmPassword: "Please confirm your password",
  birthdateRequired: "Birth date is required!",
  nationalityRequired: "Nationality is required!",
  errorSaving: "Error saving information, please try again later.",
  eduLvlRequired: "Highest education level required.",
  gradYearRequired: "Graduation year required.",
  eduInstituteRequired: "Education Institute required.",
  imageFileFormatWrong:
    "Please ensure that the uploaded file is in png/jpg/jpeg format.",
  couldNotUploadImage: "Could not upload your image, please try again later",
  uploadImageSuccess: "Image upload success",
  passwordDontMatch: "Password and Confirm Password does not match!",

  // profile
  yourResume: "Your Resume",
  viewResume: "View Resume",
  downloadResume: "Download Resume",
  uploadNewPhoto: "Upload new photo",

  // login
  emailAddress: "Email Address",
  password: "Password",
  confirmPassword: "Confirm Password",
  dontHaveAcc: "Don't have an account?",
  forgotPassword: "Forgot password?",
  emailCantBlank: "Email cannot be blank.",
  passwordCantBlank: "Password cannot be blank.",
  errorLogin: "Error while logging in. Please try again later.",
  loginSuccess: "Login Success",

  // sign up
  signup: "Sign up",
  alreadyHaveAcc: "Already have an account?",
  accExist: "Account already exist, please log instead",
  signupSuccess: "Sign up success",
  signupFail: "Sign up failed",
  pleaseConfirmPassword: "Please confirm your password.",

  // reset password
  ifEmailExist:
    "If this email has an account on NaikGaji, a reset link will be sent to the email address",
  errorAndTryAgain: "Error! Please try again later.",
  resetPassword: "Reset Password",

  // single job page
  jobDescription: "Job Description",
  jobRequirement: "Job Requirement",
  companyProfile: "Company Profile",

  // company profile
  ourStory: "Our Story",
  ourValue: "Our Value",
  ourBenefits: "Our Benefits",
  ourOffice: "Our Office",
  ourJobs: "Our Jobs",
  ourAddress: "Our Address",

  // Reset password
  setYourPassword: "Set your new password",
  backToLogin: "Back to Login",
};
