export default {
  landingPageTitle: "Tunggu Jap!",
  landingPageSubtitle: "Peluang pekerjaan tengah on the way",
  iAm: "Saya nak",
  registerInterest: "Daftar Minat",
  aJobSeeker: "Cari Kerja Kosong",
  anEmployer: "Cari Pekerja",
  submissionSuccess: "Penyerahan anda diterima!",
  invalidEmail: "Sila masukkan emel yang sah!",
  somethingWentWrong: "Maaf, sistem kami sedang diselenggara",
  somethingWentWrongSubtitle: "Mungkin anda boleh cuba link di bawah",
  selectPlaceholder: "Pilih",
  promptSelectIdentity: "Sila isikan butir-butir anda.",
  until: "sehingga",
  isLaunched: "pelancaran",
  email: "Emel",
  success: "Berjaya",
  error: "Laman tidak dijumpai",
  remindMe: "Ingatkan Saya",

  // nav bar
  login: "Log Masuk",
  signup: "Daftar",
  profile: "Profil",
  logout: "Log Keluar",
  jobs: "Pekerjaan",
  companies: "Syarikat",
  forEmployer: "Untuk Majikan",
  home: "Laman Utama",
  startHiring: "Cari Pekerja Sekarang",

  // homepage
  homepageTagline: "UPGRADE GAJI, UPGRADE HIDUP.",
  homepageSearchText: "Cari tajuk kerja atau syarikat",
  homepageSearchButtonText: "Teroka Sekarang",
  homepageTrendingTitleText: "Kerja Trending",
  homepageTrendingJobButtonText: "Teroka Lagi",

  // job card
  applyButtonText: "Mohon",
  appliedButtonText: "Mohon",
  applyJob: "Permohonan sudah dihantar!",

  // footer
  contactUs: "Hubungi Kami",
  faq: "Soalan Lazim",
  copyright: "Hakcipta ©",
  communityGuidelines: "Garis Panduan Komuniti",
  privacyPolicy: "Polisi Privasi",
  termsAndCondition: "Terma dan Syarat",
  companies: "Syarikat-syarikat",
  more: "Lain-lain",
  close: "Tutup",
  footerIntro:
    "NaikGaji adalah sebuah platform pekerjaan yang ditubuhkan pada tahun 2023 untuk membantu pekerja di Malaysia yang bukan eksekutif untuk mencari pekerjaan yang sesuai dengan kemahiran dan cita-cita mereka.",

  // jobs page
  specialisationFilter: "Kategori Kerja",
  statesFilter: "Negeri-negeri",
  jobTypeFilter: "Jenis Kerja",
  salaryFilter: "Gaji",
  search: "Cari",
  experienceLevelFilter: "Tahap Pengalaman",
  clearFilter: "Padam Semua",
  applyFilter: "Kemas Kini Carian",
  showFilters: "Tunjukkan Filters",
  hideFilters: "Sembunyikan Filters",

  // companies page
  industry: "Industri",

  // company card
  jobs: "Pekerjaan",

  // contact us page
  contactUsTitleText: "Kami amat menghargai pandangan anda.",
  contactUsFormBoxPlaceholder:
    "Jika anda mempunyai sebarang soalan, maklum balas atau cadangan, sila beritahu kami. Kami akan membalas dalam masa 1 hari berkerja.",
  submit: "Hantar",

  // single jobs page
  visitCompanyProfile: "Lihat Profil Syarikat",

  // employer page
  hireBestTalentCopy: "Hire talent like the best companies in Malaysia",

  // onboarding
  optional: "Pilihan",
  basicInfo: "Maklumat Asas",
  fullName: "Nama penuh",
  gender: "Jantina",
  birthDate: "Tarikh lahir",
  nationality: "Warganegara",
  mobileNumber: "Nombor telefon bimbit *",
  currentCompany: "Syarikat terkini",
  back: "Kembali",
  next: "Seterusnya",
  educationBg: "Latar belakang pendidikan",
  highestEduLvl: "Tahap Pendidikan Tertinggi",
  gradYear: "Tahun Kelulusan",
  eduInstitute: "Institusi Pendidikan",
  fieldOfStudy: "Bidang Pengajian",
  major: "Program Utama",
  uploadResume: "Muat Naik Resume",
  dropOrBrowse: "Muat naik resume anda di sini atau",
  browse: "cari pada komputer",
  supportFileType: "Pastikan fail dalam format .doc/.docx/.pdf",
  skip: "Langkau",
  done: "Selesai",
  save: "Kemaskini",
  shortSummary: "Ringkasan",

  // snackbar
  saveProfileSuccess: "Berjaya kemaskini profil!",
  genderIsRequired: "Sila isi jantina anda!",
  educationSaved: "Pendidikan telah dikemaskini!",
  educationCannotSave: "Gagal kemaskini pendidikan, sila cuba sebentar lagi.",
  fileFormatWrong: "Pastikan fail dalam format .doc/.docx/.pdf",
  resumeUploadSuccess: "Berjaya muat naik resume!",
  nameIsRequired: "Masukkan nama",
  phoneNumberRequired: "Masukkan nombor telefon bimbit",
  emailIsRequired: "Masukkan emel",
  passwordRequired: "Masukkan kata laluan",
  confirmPassword: "Sila sahkan kata laluan anda.",
  birthdateRequired: "Masukkan tarikh lahir",
  nationalityRequired: "Masukkan Warganegara",
  errorSaving: "Gagal kemaskini, sila cuba sebentar lagi.",
  eduLvlRequired: "Masukkan tahap pendidikan tertinggi",
  gradYearRequired: "Masukkan tahun kelulusan",
  eduInstituteRequired: "Masukkan institusi pendidikan",
  imageFileFormatWrong:
    "Sila pastikan fail yang dimuat naik adalah dalam format png/jpg/jpeg.",
  couldNotUploadImage:
    "Tidak dapat memuat naik gambar anda, sila cuba sebentar lagi.",
  uploadImageSuccess: "Berjaya muat naik gambar.",
  passwordDontMatch: "Kata Laluan dan Pengesahan Kata Laluan tidak sepadan!",

  // profile
  yourResume: "Resume anda",
  viewResume: "Lihat Resume",
  downloadResume: "Muat Turun Resume",
  uploadNewPhoto: "Muat naik Gambar",

  // login
  emailAddress: "Emel",
  password: "Kata Laluan",
  confirmPassword: "Sahkan Kata Laluan",
  dontHaveAcc: "Tidak mempunyai akaun?",
  forgotPassword: "Lupa kata laluan?",
  emailCantBlank: "Sila isi emel anda",
  passwordCantBlank: "Sila isi kata laluan anda",
  errorLogin: "Permintaan tidak dijumpai. Sila cuba sebentar lagi.",
  loginSuccess: "Log Masuk Berjaya",

  // sign up
  signup: "Daftar",
  alreadyHaveAcc: "Sudah daftar akaun?",
  accExist: "Akaun sudah daftar, sila log masuk ke akaun anda",
  signupSuccess: "Pendaftaran berjaya",
  signupFail: "Pendaftaran gagal",
  pleaseConfirmPassword: "Sila sahkan kata laluan anda.",

  // reset password
  ifEmailExist:
    "Jika emel ini mempunyai akaun di NaikGaji, link reset akan dihantar ke alamat emel.",
  errorAndTryAgain: "Laman tidak dijumpai. Sila cuba sebentar lagi.",
  resetPassword: "Reset Kata Laluan",

  // single job page
  jobDescription: "Keterangan Pekerjaan",
  jobRequirement: "Kelayakan Pemohon",
  companyProfile: "Profil Syarikat",

  // company profile
  ourStory: "Kisah Kami",
  ourValue: "Nilai-Nilai Kami",
  ourBenefits: "Faedah Kami",
  ourOffice: "Pejabat Kami",
  ourJobs: "Pekerjaan Kami",
  ourAddress: "Alamat Kami",

  // Reset password
  setYourPassword: "Tetapkan kata laluan baru",
  backToLogin: "Kembali ke Log Masuk",
};
